import { request } from '../utils/axios/request';

// 我的订单
export const $orderList = async (params) => {
  return await request.post("/order/list", { ...params });
};


// 成团详情 
export const $productDetail = async (params) => {
  return await request.post("/product/detail/" + params);
};
