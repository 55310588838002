import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import { $productDetail, $productFollow, $productRandom } from '../../api/productAPI';
import Notification from '../../components/Notification';

// 添加字体样式
const fontStyles = {
  fontFamily: "'CustomFont', sans-serif",
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale'
};

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(0);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductDetail = async () => {
      if (!id) {
        setError('Invalid product ID');
        setLoading(false);
        return;
      }
      try {
        const response = await $productDetail(id);
        setProduct(response.data);
      } catch (error) {
        console.error('Failed to fetch product details:', error);
        setError('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [id]);

  const handleJoinGroup = async () => {
    try {
      await $productFollow({ pid: id, uid: localStorage.getItem('id') });
      Notification.show('加入拼团成功！');
      navigate('/quick-group');
    } catch (error) {
      console.error('Failed to join group:', error);
      Notification.show('加入拼团失败，请重试', true);
    }
  };

  const handleRandomProduct = async () => {
    try {
      const response = await $productRandom(id);
      navigate(`/product/${response.data.id}`);
    } catch (error) {
      console.error('Failed to fetch random product:', error);
      Notification.show('获取随机项目失败，请重试', true);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className={`${styles.container} ${styles.darkBackground}`} style={fontStyles}>
      {/* 标题部分 - 现在是独立的一行 */}
      <div className={styles.pageHeader}>
        <h1 style={{ fontWeight: 'normal' }}>
          <span 
            className={styles.primaryText} 
            style={{ color: '#685aae', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            首页
          </span>
          <span className={styles.separator} style={{ color: '#685aae' }}>&gt;</span>
          <span 
            className={styles.primaryText} 
            style={{ color: '#685aae', cursor: 'pointer' }}
            onClick={() => navigate('/quick-group')}
          >
            一键成团
          </span>
          <span className={styles.separator} style={{ color: '#685aae' }}>&gt;</span>
          <span className={styles.secondaryText} style={{ color: '#685aae' }}>项目详情页</span>
        </h1>
      </div>

      {/* 主要内容区域 */}
      <div className={styles.mainContent}>
        {/* 左侧部分 */}
        <div className={styles.leftSection} style={{ marginRight: '30px' }}>
          {/* 大图展示区 */}
          <div className={styles.mainImage}>
            <img 
              src={product.img} 
              alt={product.title} 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
          
          {/* 缩略图列表 */}
          <div className={styles.thumbnails} style={{
            display: 'flex',
            overflowX: 'auto',
            gap: '10px',
            padding: '10px 0',
            scrollbarWidth: 'none', // Firefox
            msOverflowStyle: 'none', // IE/Edge
            '&::-webkit-scrollbar': { display: 'none' } // Chrome/Safari
          }}>
            {/* {product.images.map((image, index) => ( */}
              <div 
                // key={index}
                className={product.name}
                // onClick={() => setSelectedImage(index)}
                style={{
                  flexShrink: 0,
                  width: 'calc((100% - 50px) / 6)', // 6张图的宽度，减去间隔的总宽度
                  aspectRatio: '1',
                  cursor: 'pointer'
                }}
              >
                <img 
                  src={product.img} 
                  alt={product.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            {/* ))} */}
          </div>
        </div>

        {/* 右侧部分 */}
        <div className={styles.rightSection}>
          {/* 三视图展示 */}
          <div className={styles.viewImages} style={{ height: '300px' }}>
            <div className={styles.viewImage} style={{ height: '100%' }}>
              <img 
                src={product.ai2img} 
                alt={product.name}
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }} 
              />
            </div>
          </div>

          {/* 商品信息 */}
          <div className={styles.productInfo}>
            <h1 className={styles.title}>{product.name}</h1>
            <p className={styles.description}>{product.description}</p>
            
            <div className={styles.specifications}>
              <div className={styles.specItem}>
                <span className={styles.label} style={{ color: '#685aae' }}>音色：</span>
                <span style={{ color: '#685aae' }}>{product.voice}</span>
              </div>
              <div className={styles.specItem}>
                <span className={styles.label} style={{ color: '#685aae' }}>人设：</span>
                <span style={{ color: '#685aae' }}>{product.role}</span>
              </div>
              <div className={styles.specItem}>
                <span className={styles.label} style={{ color: '#685aae' }}>材质：</span>
                <span style={{ color: '#685aae' }}>{product.material}</span>
              </div>
              <div className={styles.specItem}>
                <span className={styles.label} style={{ color: '#685aae' }}>大小：</span>
                <span style={{ color: '#685aae' }}>{product.size}</span>
              </div>
            </div>
          </div>

          {/* 拼团信息 */}
          <div className={styles.groupInfo}>
            <div className={styles.groupInfoItem} style={{ 
              backgroundColor: '#cfb8fe', 
              color: 'white',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.2em'
            }}>
              <span className={styles.label}>拼团进度 {product.followed}/{product.num}人</span>
            </div>
            <div className={styles.groupInfoItem} style={{ 
              backgroundColor: 'white',
              height: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              position: 'relative',
              padding: '8px'
            }}>
              <span className={styles.label} style={{ 
                backgroundColor: 'white', 
                color: '#685aae',
                fontSize: '1.2em'
              }}>
                ¥{product.price}
              </span>
              <span style={{ 
                position: 'absolute',
                top: '8px',
                right: '8px',
                fontSize: '1.2em',
                textDecoration: 'line-through',
                color: '#999'
              }}>
                ¥{product.old_price}
              </span>
            </div>
            <button 
              className={`${styles.groupInfoItem} ${styles.joinButton}`} 
              style={{ 
                ...fontStyles,
                backgroundColor: '#cfb8fe',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
                height: '60px',
                padding: '0',
                borderRadius: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '1.2em'
              }}
              onClick={handleJoinGroup}
            >
              加入拼团
            </button>
          </div>
          <div className={styles.groupTip}>
            温馨提示：拼团成功后将为您发送购买链接
          </div>
          <div className={styles.editButtonContainer}>
            <button 
              className={styles.editButton} 
              style={fontStyles}
              onClick={handleRandomProduct}
            >
              <div style={{ display: 'block' }}>随机看看</div>
              <div style={{ display: 'block', fontSize: '0.8em', marginTop: '4px', opacity: 0.8 }}>随机刷新项目</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail; 