import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import LoginModal from '../LoginModal';
import Swal from 'sweetalert2';
import { Popover, Button } from 'antd';
import { LogoutOutlined, OrderedListOutlined } from '@ant-design/icons';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [targetPath, setTargetPath] = useState('');
  const [hoveredLink, setHoveredLink] = useState(null);

  const handleNavClick = (e, path) => {
    console.log('handleNavClick triggered', { path });  // 调试日志
    
    if (path === '/' || path === '/gengduo' || path === '/geren') return;
    
    // 对于 /news 和 /contact 路径显示美化后的"敬请期待"提示
    if (path === '/news' || path === '/contact') {
      e.preventDefault();
      Swal.fire({
        title: '敬请期待',
        text: '该功能正在开发中，即将上线！',
        icon: 'info',
        confirmButtonText: '好的',
        confirmButtonColor: '#3085d6',
        background: '#fff',
        backdrop: `
          rgba(0,0,123,0.4)
          left top
          no-repeat
        `,
        customClass: {
          title: 'custom-swal-title',
          popup: 'custom-swal-popup'
        }
      });
      return;
    }
    
    const isLoggedIn = localStorage.getItem('token');
    console.log('isLoggedIn:', isLoggedIn);
    
    if (!isLoggedIn) {
      e.preventDefault();
      setTargetPath(path);
      setIsLoginModalOpen(true);
      console.log('Login modal should open now');
      return;
    }

    // 添加这个判断来处理订单页面的跳转
    if (path === '/orders' && isLoggedIn) {
      window.location.href = path;
      return;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const popoverContent = () => {
    const isLoggedIn = localStorage.getItem('token');
    
    if (isLoggedIn) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Button 
            type="text" 
            icon={<OrderedListOutlined />}
            onClick={(e) => {
              e.preventDefault();
              handleNavClick(e, '/orders');
            }}
          >
            我的订单
          </Button>
          <Button 
            type="text" 
            icon={<LogoutOutlined />}
            onClick={handleLogout}
          >
            退出登录
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Button 
            type="text" 
            onClick={() => setIsLoginModalOpen(true)}
          >
            登录
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <Link to="/" className={styles.logo}>
            <img src="/logo.png" alt="Logo" />
          </Link>
          
          <div className={styles.rightSection}>
            <button 
              className={`${styles.menuButton} ${isMenuOpen ? styles.active : ''}`}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <nav className={`${styles.nav} ${isMenuOpen ? styles.active : ''}`}>
              <Link to="/" 
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('home')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => {
                  console.log('Home link clicked');
                  handleNavClick(e, '/')
                }}>
                <div>首页</div>
              </Link>
              <Link to="/quick-group" 
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('qg')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => {
                  console.log('About link clicked');
                  handleNavClick(e, '/quick-group')
                }}>
                <div>一键成团</div>
              </Link>
              <Link to="/custom-design" 
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('cd')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => {
                  console.log('About link clicked');
                  handleNavClick(e, '/quick-group')
                }}>
                <div>随心定</div>
              </Link>
              <Link to="/app-square"
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('products')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => handleNavClick(e, '/app-square')}>
                <div>随心创</div>
              </Link>
              <Link to="/ai-chat"
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('aiChat')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => handleNavClick(e, '/ai-chat')}>
                <div>随心聊</div>
              </Link>
              <Link to="/news"
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('news')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => handleNavClick(e, '/news')}>
                <div>达人案例</div>
              </Link>
              <Link to="/contact"
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('contact')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => handleNavClick(e, '/contact')}>
                <div>更多内容</div>
              </Link>
              <Link to="#"
                className={styles.navLink}
                onMouseEnter={() => setHoveredLink('gengduo')}
                onMouseLeave={() => setHoveredLink(null)}
                onClick={e => handleNavClick(e, '/gengduo')}>
                <div>更多</div>
              </Link>
              <Popover 
                content={popoverContent()}
                trigger="hover"
                placement="bottom"
              >
                <Link to="#"
                  className={styles.navLink}
                  onMouseEnter={() => setHoveredLink('geren')}
                  onMouseLeave={() => setHoveredLink(null)}
                  onClick={e => handleNavClick(e, '/geren')}>
                  <div>个人中心</div>
                </Link>
              </Popover>
            </nav>
          </div>
        </div>
      </header>
      
      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={() => {
          setIsLoginModalOpen(false);
          window.location.href = targetPath;
        }}
      />
    </>
  );
};

export default Header; 