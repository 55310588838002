import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import styles from './QuickGroup.module.css';
import { useNavigate } from 'react-router-dom';
import { $productList, $productMy, $productRandom } from '../../api/productAPI';

const QuickGroup = () => {
    const [activeTab, setActiveTab] = useState('explore');
    const [showModal, setShowModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [inputText, setInputText] = useState('');
    const [tags, setTags] = useState([]);
    const [activeCategory, setActiveCategory] = useState('custom');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const navigate = useNavigate();
    const [exploreTeams, setExploreTeams] = useState([]);
    const [popularTeams, setPopularTeams] = useState([]);
    const heroImages = [
      '/feature1.jpg',
      '/feature1.jpg',
      '/feature1.jpg',
      '/feature1.jpg'
    ];
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // 初始化时只获取成团广场数据
          const exploreResponse = await $productList();
          setExploreTeams(exploreResponse.data.list);
        } catch (error) {
          console.error('Failed to fetch teams:', error);
        }
      };

      fetchData();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (activeTab === 'popular') {
            // 切换到"我的拼团"时获取数据
            const popularResponse = await $productMy({ uid: localStorage.getItem('id') });
            setPopularTeams(popularResponse.data.list);
          } else if (activeTab === 'explore') {
            // 切换到"成团广场"时获取数据
            const exploreResponse = await $productList();
            setExploreTeams(exploreResponse.data.list);
          }
        } catch (error) {
          console.error('Failed to fetch teams:', error);
        }
      };

      fetchData();
    }, [activeTab]); // 当 activeTab 改变时触发
  
    const handleProductSelect = (productId) => {
      setSelectedProducts(prev => {
        if (prev.includes(productId)) {
          return prev.filter(id => id !== productId);
        }
        return [...prev, productId];
      });
    };
  
    const handleAddTag = () => {
      if (inputText.trim()) {
        setTags(prev => [...prev, inputText.trim()]);
        setInputText('');
      }
    };
  
    const handleRemoveTag = (tagToRemove) => {
      setTags(prev => prev.filter(tag => tag !== tagToRemove));
    };

    const handleProductClick = (productId) => {
      navigate(`/product/${productId}`);
    };

  const getPageCount = (items) => {
    return Math.ceil((items?.length || 0) / itemsPerPage);
  };

  const getPageItems = (items) => {
    if (!Array.isArray(items)) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleConfirm = async () => {
    try {
      const response = await $productRandom(0);
      if (response.data && response.data.id) {
        navigate(`/product/${response.data.id}`);
      }
    } catch (error) {
      console.error('Failed to get random product:', error);
    }
    setShowModal(false);
  };

  return (
    <Layout>
      <div className={`${styles.container} ${styles.darkBackground}`}>
        <div className={`${styles.header} ${styles.pageTitle}`}>
          <h1 style={{ color: '#3e3470' }}>
            <span 
              onClick={() => navigate('/')} 
              style={{ cursor: 'pointer' }}
            >
              首页
            </span>
            <span className={styles.separator}>&gt;</span> 一键成团
          </h1>
        </div>

        {/* 背景图片和悬浮文字部分 */}
        <div className={styles.heroSection} onClick={() => setShowModal(true)}>
          <div className={styles.imageRow}>
            <div className={styles.imageContainer}>
              {heroImages.map((image, index) => (
                <img
                  key={`right-${index}`}
                  src={image}
                  alt="点击这里开始你的探索队列"
                  className={`${styles.heroImage} ${styles.scrollLeft}`}
                />
              ))}
              {/* Duplicate the images for seamless loop */}
              {heroImages.map((image, index) => (
                <img
                  key={`right-dup-${index}`}
                  src={image}
                  alt="点击这里开始你的探索队列"
                  className={`${styles.heroImage} ${styles.scrollLeft}`}
                />
              ))}
            </div>
            <div className={styles.imageContainer}>
              {heroImages.map((image, index) => (
                <img
                  key={`left-${index}`}
                  src={image}
                  alt="点击这里开始你的探索队列"
                  className={`${styles.heroImage} ${styles.scrollRight}`}
                />
              ))}
              {/* Duplicate the images for seamless loop */}
              {heroImages.map((image, index) => (
                <img
                  key={`left-dup-${index}`}
                  src={image}
                  alt="点击这里开始你的探索队列"
                  className={`${styles.heroImage} ${styles.scrollRight}`}
                />
              ))}
            </div>
          </div>
        </div>

        {/* 新增的标签栏 */}
        <div className={styles.categoryTabs}>
          <div>
            <button 
              className={`${styles.categoryTab} ${activeCategory === 'custom' ? styles.active : ''}`}
              onClick={() => setActiveCategory('custom')}
            >
              自定义探索队列
            </button>
          </div>
          <div>
            <button 
              className={`${styles.categoryTab} ${activeCategory === 'tags' ? styles.active : ''}`}
              onClick={() => setActiveCategory('tags')}
            >
              挑选您喜欢的标签类型
            </button>
          </div>
        </div>

        {/* Tab栏部分 */}
        <div className={styles.tabContainer}>
          <div>
            <button 
              className={`${styles.categoryTabSec} ${activeTab === 'explore' ? styles.active : ''}`}
              onClick={() => setActiveTab('explore')}
            >
              成团广场
            </button>
          </div>
          <div>
            <button 
              className={`${styles.categoryTabSec} ${activeTab === 'popular' ? styles.active : ''}`}
              onClick={() => setActiveTab('popular')}
            >
              我的拼团
            </button>
          </div>
        </div>

        {/* 内容区域 */}
        {activeTab === 'explore' && (
          <>
            <div className={styles.teamGrid}>
              {getPageItems(exploreTeams).map((team) => (
                <div 
                  key={team.id} 
                  className={styles.teamCard}
                  onClick={() => handleProductClick(team.id)}
                  role="button"
                  tabIndex={0}
                >
                  {/* 上部分 - 图片容器 */}
                  <div className={styles.imageContainer}>
                    <img src={team.img} alt={team.name} className={styles.teamImage} />
                  </div>
                  
                  {/* 下部分 - 文字内容容器 */}
                  <div className={styles.contentContainer}>
                    <div className={styles.middleSection}>
                      <h2 className={styles.productName}>{team.name}</h2>
                      <div className={styles.price}>均价：¥{team.price}</div>
                    </div>
                    
                    <div className={styles.bottomSection}>
                      <div 
                        className={styles.description} 
                        title={team.description}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {team.description}
                      </div>
                      <div className={styles.groupInfo}>
                        {team.followed}/{team.num}人团
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.paginationContainer}>
              <button 
                className={styles.paginationButton}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >上一页</button>
              {[...Array(getPageCount(exploreTeams))].map((_, index) => (
                <button
                  key={index + 1}
                  className={`${styles.paginationButton} ${currentPage === index + 1 ? styles.active : ''}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button 
                className={styles.paginationButton}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === getPageCount(exploreTeams)}
              >下一页</button>
            </div>
          </>
        )}

        {activeTab === 'popular' && (
          <>
          <div className={styles.teamGrid}>
            {getPageItems(popularTeams).map((team) => (
              <div 
                key={team.id} 
                className={styles.teamCard}
                onClick={() => handleProductClick(team.id)}
                role="button"
                tabIndex={0}
              >
                {/* 上部分 - 图片容器 */}
                <div className={styles.imageContainer}>
                  <img src={team.img} alt={team.name} className={styles.teamImage} />
                </div>
                
                {/* 下部分 - 文字内容容器 */}
                <div className={styles.contentContainer}>
                  <div className={styles.middleSection}>
                    <h2 className={styles.productName}>{team.name}</h2>
                    <div className={styles.price}>均价：¥{team.price}</div>
                  </div>
                  
                  <div className={styles.bottomSection}>
                    <div 
                      className={styles.description} 
                      title={team.description}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {team.description}
                    </div>
                    <div className={styles.groupInfo}>
                      {team.followed}/{team.num}人团
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.paginationContainer}>
              <button 
                className={styles.paginationButton}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >上一页</button>
              {[...Array(getPageCount(popularTeams))].map((_, index) => (
                <button
                  key={index + 1}
                  className={`${styles.paginationButton} ${currentPage === index + 1 ? styles.active : ''}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button 
                className={styles.paginationButton}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === getPageCount(popularTeams)}
              >下一页</button>
            </div>
          </>
        )}

        {/* Modal弹窗 */}
        {showModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h2 className={styles.modalTitle}>定制您的探索队列</h2>
                <p className={styles.subtitle}>挑选以下选项来指定您想在您的探索队列中包括或去除的产品类型。</p>
                <button 
                  className={styles.closeButton}
                  onClick={() => setShowModal(false)}
                >
                  ×
                </button>
              </div>
              
              <div className={styles.modalBody}>
                <div className={styles.modalBodyContainer}>
                  <div className={styles.productTypesSection}>
                    <h3>包含以下产品类型：</h3>
                    <div className={styles.checkboxList}>
                      {[
                        '二创游戏IP',
                        '二创影视IP',
                        '二创动漫IP',
                        '二创真人idol',
                        '二创品牌IP'
                      ].map((type, index) => (
                        <label key={index} className={styles.checkboxItem}>
                          <input
                            type="checkbox"
                            checked={selectedProducts.includes(index)}
                            onChange={() => handleProductSelect(index)}
                          />
                          <span>{type}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div className={styles.tagSection}>
                    <h3>添加感兴趣的标签：</h3>
                    <div className={styles.tagInput}>
                      <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        placeholder="输入一个标签"
                      />
                      <button onClick={handleAddTag}>添加</button>
                    </div>
                    <div className={styles.tagList}>
                      {tags.map((tag, index) => (
                        <span key={index} className={styles.tag}>
                          {tag}
                          <button 
                            onClick={() => handleRemoveTag(tag)}
                            className={styles.removeTag}
                          >
                            ×
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.modalFooter}>
                <button 
                  className={styles.confirmButton}
                  onClick={handleConfirm}
                >
                  确定
                </button>
                <button 
                  className={styles.cancelButton}
                  onClick={() => setShowModal(false)}
                >
                  取消
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default QuickGroup;