import axios from "axios";
import { baseURL } from "../../config/config";
import { removeToken } from "../../utils/func";
import { message } from "antd";

export const request = axios.create({
  baseURL,
  timeout: 10000,
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 所有请求带上认证头
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.setAuthorization(token);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 服务端 token 过期或未登录
    if (response.data.code === 60000 || response.data.code === 40000) {
      removeToken();
      message.error("Token 无效或已过期，请重新登录！");
      // setTimeout(() => (window.location.href = "/"), 2000);
      window.location.href = "/";
    }
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);
