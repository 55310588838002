import React, { useState } from 'react';
import Layout from '../../components/Layout';
import styles from './CustomDesign.module.css';
import { customImageURL } from '../../config/config';
import { Upload, Form, Radio, Select, Checkbox, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { $add } from '../../api/productAPI';

const CustomDesign = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [previewImage, setPreviewImage] = useState(null);
  const [gridItems, setGridItems] = useState(Array(20).fill(null));
  const [traitButtons, setTraitButtons] = useState([
    {
      id: 1, name: 'MBTI', items: [
        { letter: 'I', color: '#b19cd9' },
        { letter: 'N', color: '#b19cd9' },
        { letter: 'F', color: '#b19cd9' },
        { letter: 'T', color: '#b19cd9' },
        { letter: 'I', color: '#b19cd9' },
        { letter: 'S', color: '#b19cd9' },
        { letter: 'F', color: '#b19cd9' },
        { letter: 'J', color: '#b19cd9' },
      ]
    },
    {
      id: 2, name: '背景设定', items: [
        { letter: 'E', color: '#ffa500' },
        { letter: 'N', color: '#ffa500' },
        { letter: 'F', color: '#ffa500' },
      ]
    },
    {
      id: 3, name: '对话风格', items: [
        { letter: 'P', color: '#90ee90' },
        { letter: 'T', color: '#90ee90' },
      ]
    },
  ]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const steps = [
    { title: '形象创建', status: currentStep === 1 ? 'current' : currentStep > 1 ? 'done' : 'wait' },
    { title: '美图渲染 (AI)', status: currentStep === 2 ? 'current' : currentStep > 2 ? 'done' : 'wait' },
    { title: '预览效果', status: currentStep === 3 ? 'current' : currentStep > 3 ? 'done' : 'wait' },
    { title: '下单', status: currentStep === 4 ? 'current' : currentStep > 4 ? 'done' : 'wait' },
    { title: '物流发货', status: currentStep === 5 ? 'current' : currentStep > 5 ? 'done' : 'wait' }
  ];

  const handleNextStep = () => {
    if (currentStep === 1 && !generatedImage) {
      alert('请生成形象');
      return;
    }

    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleImageUpload = (info) => {
    const file = info.file;
    if (file) {
      setUploadedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitOrder = async (values) => {
    console.log('Received values:', values);
    const params = {
      ...values,
      img: originalImage,
      ai2img: generatedImage,

    }
    const response = await $add(values);
    if (response.code === 10000) {
      messageApi.open({
        type: 'success',
        content: '下单成功',
      });
      navigate('/');
    } else {
      messageApi.open({
        type: 'error',
        content: '下单失败' + response.msg,
      });
    }
    // window.open('YOUR_TAOBAO_SHOP_URL', '_blank');
  }

  const handleGenerateImage = async () => {
    if (!previewImage) {
      alert('请先上传图片');
      return;
    }

    setIsGenerating(true);
    try {
      const formData = new FormData();
      formData.append('image', uploadedFile);
      formData.append('prompt', prompt);

      const response = await fetch(customImageURL, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('生成失败');
      }

      const data = await response.json();
      console.log('Generated image result:', data.data.view);
      setGeneratedImage(data.data.view);
      setOriginalImage(data.data.image);

    } catch (error) {
      console.error('Error generating image:', error);
      alert('生成失败，请重试');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDragStart = (e, item, source, categoryName) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({
      item,
      source,
      categoryName,
      color: item.color
    }));
  };

  const handleDrop = (e, targetIndex, targetArea) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    const { item, source, categoryName, color } = data;

    if (targetArea === 'grid' && source === 'trait') {
      const newGridItems = [...gridItems];
      newGridItems[targetIndex] = {
        letter: item.letter,
        color: color,
        categoryName
      };
      setGridItems(newGridItems);

      // Remove from trait buttons
      const newTraitButtons = traitButtons.map(category => {
        if (category.name === categoryName) {
          return {
            ...category,
            items: category.items.filter(i => i !== item)
          };
        }
        return category;
      });
      setTraitButtons(newTraitButtons);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className={styles.stepContent}>
            <div className={styles.designContainer}>
              {/* Left Section */}
              <div className={styles.leftSection}>
                <div className={styles.sectionTitle}>形象创建</div>
                <div className={styles.uploadSection}>
                  <Upload
                    name="image"
                    accept="image/*"
                    onChange={handleImageUpload}
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleImageUpload({ file });
                      return false;
                    }}
                  >
                    <button className={styles.uploadButton}>上传图片</button>
                  </Upload>
                  <div className={styles.imagePreview}>
                    {previewImage ? (
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain'
                        }}
                        className={styles.previewImg}
                      />
                    ) : (
                      <div className={styles.placeholderText}>
                        图片预览
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.promptSection}>
                  <textarea
                    className={styles.promptInput}
                    placeholder="请输入提示词..."
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                  <button
                    className={styles.generateButton}
                    onClick={handleGenerateImage}
                    disabled={isGenerating}
                  >
                    {isGenerating ? '生成中...' : '生成形象'}
                  </button>
                </div>
              </div>

              {/* Right Section */}
              <div className={styles.rightSection}>
                <div className={styles.sectionTitle}>生成结果</div>
                <div className={styles.generatedResults}>
                  {generatedImage && (
                    <img
                      src={generatedImage}
                      alt="Generated result"
                      className={styles.generatedImg}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.stepContent}>
            <div className={styles.aiContainer}>
              <div className={styles.tabButtons}>
                <button className={`${styles.tabButton} ${styles.active}`}>创造模式</button>
                <button className={styles.tabButton}>专家模式</button>
              </div>

              <div className={styles.aiContent}>
                {/* Wrapped panels in a container for better mobile layout control */}
                <div className={styles.panelsContainer}>
                  <div className={styles.leftPanel}>
                    <div className={styles.gridContainer}>
                      {gridItems.map((item, index) => (
                        <div
                          key={index}
                          className={styles.gridItem}
                          draggable={!!item}
                          onDragStart={(e) => handleDragStart(e, { index, value: item }, 'grid')}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, index, 'grid')}
                          style={item ? { backgroundColor: item.color } : {}}
                        >
                          {item ? item.letter : ''}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={styles.rightPanel}>
                    <div className={styles.modelBank}>
                      <h3>模组库</h3>
                      <div className={styles.traits}>
                        {traitButtons.map((category) => (
                          <div key={category.id} className={styles.traitCategory}>
                            <div className={styles.traitTitle}>{category.name}</div>
                            <div
                              className={styles.traitButtons}
                              onDragOver={handleDragOver}
                            >
                              {category.items.map((item, index) => (
                                <button
                                  key={index}
                                  className={styles.traitButton}
                                  style={{ backgroundColor: item.color }}
                                  draggable={true}
                                  onDragStart={(e) => handleDragStart(e, item, 'trait', category.name)}
                                >
                                  {item.letter}
                                </button>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className={styles.stepContent}>
            <div className={styles.previewContainer}>
              {/* Preview sections will stack vertically on mobile */}
              <div className={styles.previewSection}>
                <div className={styles.sectionTitle}>互动效果</div>
                <div className={styles.interactionPreview}>
                  <div className={styles.previewPlaceholder}>
                    互动效果展示区域
                  </div>
                </div>
              </div>

              <div className={styles.previewSection}>
                <div className={styles.sectionTitle}>对话效果</div>
                <div className={styles.dialoguePreview}>
                  <div className={styles.previewPlaceholder}>
                    对话效果展示区域
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.stepContent}>
            <div className={styles.orderContainer}>
              {/* Left Section */}
              <div className={styles.orderLeftSection}>
                {/* Product Images */}
                <div className={styles.productImages}>
                  {generatedImage && (
                    <img
                      src={generatedImage}
                      alt="Generated result"
                      className={styles.productImage}
                    />
                  )}
                </div>
                {/* Product Details */}
                <div className={styles.productDetails}>
                  <h3>商品简介</h3>
                  <div className={styles.detailRow}>
                    <span>色彩：暖暖深蓝</span>
                    <span>材质：PVC手办</span>
                  </div>
                  <div className={styles.detailRow}>
                    <span>人设：原创女仆</span>
                    <span>大小：8厘米</span>
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className={styles.orderRightSection}>
                <div className={styles.orderForm}>
                  <Form onFinish={submitOrder}>
                    <Form.Item name="type" label="定制类型">
                      <Radio.Group optionType="button">
                        <Radio value="1">无AI版</Radio>
                        <Radio value="2">AI模板声音版</Radio>
                        <Radio value="3">AI音色克隆版</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item name="touch" label="触碰交互">
                      <Radio.Group optionType="button">
                        <Radio value="1">无触摸</Radio>
                        <Radio value="2">有触摸</Radio>
                      </Radio.Group>
                    </Form.Item>

                    {/* <Form.Item label="模板声音">
                      <Select>
                        <Select.Option value="sound1">声音1</Select.Option>
                        <Select.Option value="sound2">声音2</Select.Option>
                      </Select>
                    </Form.Item>
                    
                    <Form.Item>
                      <Checkbox>人设定制</Checkbox>
                    </Form.Item> */}

                    <Form.Item name="num" label="定制数量">
                      <Input type="quantity" defaultValue={1} />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className={styles.taobaoButton}
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: '#FF4400', // Taobao's signature orange color
                          color: 'white',
                          padding: '12px 24px',
                          border: 'none',
                          borderRadius: '4px',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          width: '100%',
                          transition: 'background-color 0.2s ease',
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#FF6633'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#FF4400'}
                      >
                        <img
                          src="/icon_taobao.png"
                          alt="淘宝"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '8px'
                          }}
                        />
                        前往淘宝店铺下单
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return <div className={styles.stepContent}>物流信息</div>;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        {contextHolder}
        {/* Header */}
        <div className={styles.header}>
          <h1>
            <span
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            >
              首页
            </span>
            &gt; 专属定制
          </h1>
        </div>

        {/* Progress Steps */}
        <div className={styles.progressSteps}>
          {steps.map((step, index) => (
            <div key={index} className={styles.stepItem}>
              <div className={styles.stepCircle}>
                <span className={`${styles.stepNumber} ${styles[step.status]}`}>
                  {index + 1}
                </span>
              </div>
              <div className={styles.stepContent1}>
                <div className={`${styles.stepTitle} ${styles[step.status]}`}>
                  {step.title}
                </div>
              </div>
              {index < steps.length - 1 && (
                <div className={`${styles.stepLine} ${styles[step.status]}`} />
              )}
            </div>
          ))}
        </div>

        {/* Content Section */}
        <div className={styles.content} style={{
          marginBottom: '80px' // 为固定按钮留出空间
        }}>
          {renderContent()}
        </div>
        {/* Navigation Buttons */}
        <div className={styles.buttonGroup} style={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: '15px 30px',
          background: 'white',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          zIndex: 100
        }}>
          {currentStep === 4 ? (
            <>
              <button
                className={`${styles.button} ${styles.cancelButton}`}
                onClick={handlePrevStep}
              >
                取消
              </button>
              <button
                className={`${styles.button}`}
                // onClick={handleNextStep}
                disabled
              >
                去支付
              </button>
            </>
          ) : (
            <>
              {currentStep > 1 && (
                <button
                  className={`${styles.button} ${styles.prevButton}`}
                  onClick={handlePrevStep}
                >
                  上一步
                </button>
              )}
              {currentStep < steps.length && (
                <button
                  className={`${styles.button} ${styles.nextButton}`}
                  onClick={handleNextStep}
                >
                  下一步
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CustomDesign; 