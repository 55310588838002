import QuickGroup from '../pages/QuickGroup';
import Home from '../pages/Home';
import ProductDetail from '../pages/ProductDetail';
import CustomDesign from '../pages/CustomDesign';
import Orders from '../pages/Orders';
import AppSquare from '../pages/AppSquare';
import ImageGeneration from '../pages/ImageGeneration';
import CaiShenGeneration from '../pages/CaiShenGeneration';
import AIGeneration from '../pages/AIGeneration';
import AIChat from '../pages/AIChat';

export const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/quick-group',
    element: <QuickGroup />
  },
  {
    path: '/product/:id',
    element: <ProductDetail />
  },
  {
    path: '/custom-design',
    element: <CustomDesign />
  },
  {
    path: '/orders',
    element: <Orders />
  },
  {
    path: '/app-square',
    element: <AppSquare />
  },
  {
    path: '/image-generation',
    element: <ImageGeneration />
  },
  {
    path: '/cai-shen-generation',
    element: <CaiShenGeneration />
  },
  {
    path: '/ai-generation',
    element: <AIGeneration />
  },
  {
    path: '/ai-chat',
    element: <AIChat />
  },
];
