import { request } from '../utils/axios/request';

// 拼团广场
export const $productList = async (params) => {
  return await request.post("/product/list", { ...params });
};

// 我的拼团
export const $productMy = async (params) => {
  return await request.post("/product/follows", { ...params });
};

// 成团详情 
export const $productDetail = async (params) => {
  return await request.post("/product/detail/" + params);
};

// 拼团 
export const $productFollow = async (params) => {
  return await request.post("/product/join/"+params.pid+"/"+params.uid);
};

// 成团详情 
export const $productRandom = async (params) => {
  return await request.post("/product/detail/random/"+params);
};

// 下单
export const $add = async (params) => {
  return await request.post("/product/add", { ...params });
};
