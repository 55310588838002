import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, message, Image } from 'antd';
import axios from 'axios';
import styles from './Orders.module.css';
import { $orderList } from '../../api/orderAPI';
import { ossBaseURL } from "../../config/config";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
    total: 0
  });

  const fetchOrders = async (current = 1, size = 10) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error('请先登录');
        window.location.href = '/';
        return;
      }

      setLoading(true);
      const response = await $orderList({ current, size });
      console.log('response:', response);
      setOrders(response.data.list);
      setPagination({
        ...pagination,
        current,
        total: response.data.page.total
      });
    } catch (error) {
      message.error('获取订单失败');
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: '原图',
      dataIndex: "image",
      render: (image) => (
        <Image
          width={100}
          src={ossBaseURL + image}
          alt="订单图片"
        />
      ),
      key: 'image',
    },
    {
        title: '订单编号',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: '定制类型',
        dataIndex: 'type',
        render: (type) => {
            if (type === 1) {
              return <Tag color="">普通版</Tag>;
            }
            if (type === 2) {
              return <Tag color="">模板声音AI版</Tag>;
            }
            if (type === 3) {
              return <Tag color="">声音克隆AI版</Tag>;
            }
          },
        key: 'type',
    },
    {
        title: '触碰交互',
        dataIndex: "touch",
            render: (touch) => {
            return touch === 2 ? (
                <Tag color="">有触碰</Tag>
            ) : (
                <Tag color="">无触碰</Tag>
            );
        },
        key: 'touch',
    },
    {
        title: '单价',
        dataIndex: "price",
        render: (price) => "¥" + price + "/套",
        key: 'price',
    },
    {
        title: '数量',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: '总价',
        dataIndex: "amount",
        render: (amount) => "¥" + amount,
        key: 'amount',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = 'blue';
        let text = '处理中';
        
        switch (status) {
          case 'completed':
            color = 'green';
            text = '已完成';
            break;
          case 'pending':
            color = 'orange';
            text = '待付款';
            break;
          case 'cancelled':
            color = 'red';
            text = '已取消';
            break;
          default:
            break;
        }
        
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleViewDetails(record.id)}>查看详情</a>
          {record.status === 'pending' && (
            <a onClick={() => handlePayment(record.id)}>去支付</a>
          )}
        </Space>
      ),
    },
  ];

  const handleViewDetails = (orderId) => {
    // 实现查看订单详情的逻辑
    console.log('View details for order:', orderId);
  };

  const handlePayment = (orderId) => {
    // 实现支付逻辑
    console.log('Process payment for order:', orderId);
  };

  return (
    <div className={styles.ordersContainer}>
      <h1 className={styles.title}>
        <span 
          onClick={() => window.location.href = '/'} 
          style={{ cursor: 'pointer' }}
        >
          首页
        </span>
        &gt;我的订单
      </h1>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
        scroll={{ x: 1300 }}
        pagination={{
          ...pagination,
          showTotal: (total) => `共 ${total} 条订单`,
          onChange: (page, pageSize) => fetchOrders(page, pageSize)
        }}
      />
    </div>
  );
};

export default Orders; 