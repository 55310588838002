const hostname = window.location.hostname;

// OSS
// export const ossBaseURL =
//   hostname === "localhost" || hostname === "127.0.0.1"
//     ? "https://wwkl-web-custom-dev.oss-cn-hangzhou.aliyuncs.com/"
//     : "https://wwkl-web-custom-prod.oss-cn-hangzhou.aliyuncs.com/";

export const ossBaseURL = "https://wwkl-web-custom-prod.oss-cn-hangzhou.aliyuncs.com/";

// API
// export const baseURL =
//   hostname === "localhost" || hostname === "127.0.0.1"
//     ? "http://localhost:8080"
//     : "https://xl-api.52agent.com";
export const baseURL = "https://api.52agent.com";

export const customImageURL = baseURL + "/custom/image";
export const customVoiceURL = baseURL + "/custom/voice";
export const customKnowledgeURL = baseURL + "/custom/knowledge";
export const customCaiShenURL = baseURL + "/custom/caishen";
export const aiGenerationURL = baseURL + "/aiConfig/generation/";

// 模板声音
export const voiceType = [
  {
    value: "VB001",
    name: "普通男声",
  },
  {
    value: "VB002",
    name: "普通女声",
  },
];

// 人设定制
export const role = [
  "《代号鸢》孙策",
  "《恋与深空》黎深",
  "《斗罗大陆》唐三",
  "《一人之下》张楚岚",
  "文俊辉（SEVENTEEN）",
  "张真源（TNT）",
  "海尔兄弟（海尔）",
  "旺仔（旺旺）",
  "银行理财顾问",
  "保险销售顾问",
];
