import { useState } from 'react';
import { Input, Button, Space, message } from 'antd';
import { $sms, $login } from '../../api/userAPI';
import { isMobile, setToken } from "../../utils/func";
import styles from './LoginModal.module.css';

function LoginModal({ isOpen, onClose, onLoginSuccess }) {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [sending, setSending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  
  // 验证手机号格式
  const isPhoneValid = /^1[3-9]\d{9}$/.test(phone);
  // 验证码长度检查（假设验证码是6位）
  const isCodeValid = code.length === 6;
  
  const handleSendCode = async () => {
    if (!isPhoneValid || sending) {
      return;
    }
    
    try {
      setSending(true);
      const res = await $sms({ username: phone });
      
      if (res.code === 200) {
        message.success('验证码发送成功');
        // 开始60秒倒计时
        setCountdown(60);
        const timer = setInterval(() => {
          setCountdown(prev => {
            if (prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        message.error(res.message || '发送失败，请稍后重试');
      }
    } catch (error) {
      message.error('发送失败，请稍后重试');
    } finally {
      setSending(false);
    }
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isPhoneValid || !isCodeValid) {
      return;
    }

    try {
      const res = await $login({
        mode: 2,
        username: phone,
        key: code 
      });
      if (res.code === 10000) {
        // 缓存 token
        setToken(
            res.data.id,
            res.data.username,
            res.data.token,
            res.data.expiresIn
        );
        message.success('登录成功');
        onLoginSuccess(res.data); // 传递用户数据给父组件
        onClose(); // 关闭登录窗口
      } else if (res.code === 30020) {
        message.error('登录失败，请稍后重试' + res.msg);
      } else {
        message.error(res.msg, 5);
      }
    } catch (error) {
      message.error('登录失败，请稍后重试');
    }
  };
  
  if (!isOpen) return null;
  
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2 className={styles.title}>潮玩随心定，欢迎您使用！</h2>
        <button className={styles.closeButton} onClick={onClose}>
          <span>×</span>
        </button>
        
        <form onSubmit={handleLogin}>
          <div className={styles.inputGroup}>
            <label>手机号</label>
            <Input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="请输入手机号"
              maxLength={11}
              status={phone && !isPhoneValid ? 'error' : ''}
            />
          </div>
          
          <div className={styles.inputGroup}>
            <label>验证码</label>
            <Space.Compact style={{ width: '100%' }}>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value.replace(/\D/g, ''))}
                placeholder="请输入验证码"
                maxLength={6}
              />
              <Button 
                onClick={handleSendCode}
                disabled={!isPhoneValid || countdown > 0}
                loading={sending}
                style={{ fontFamily: 'CustomFont' }}
              >
                {countdown > 0 ? `${countdown}秒后重试` : '发送验证码'}
              </Button>
            </Space.Compact>
          </div>
          
          <Button 
            type="primary" 
            htmlType="submit" 
            block 
            className={styles.submitButton}
            disabled={!isPhoneValid || !isCodeValid}
          >
            登录 / 注册
          </Button>
        </form>
      </div>
    </div>
  );
}

export default LoginModal; 