import React, { useState } from 'react';
import styles from './ImageGeneration.module.css';

const ImageGeneration = () => {
  const [prompt, setPrompt] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) return;
    
    setIsLoading(true);
    try {
      // 这里添加调用 AI 图片生成 API 的逻辑
      // const response = await generateImage(prompt);
      // setGeneratedImage(response.imageUrl);
      
      // 临时模拟生成图片
      // 模仿customDesign的步骤一
      setTimeout(() => {
        setGeneratedImage('/placeholder-image.jpg');
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error('生成图片时出错:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>AI 图片生成</h1>
      
      <div className={styles.inputSection}>
        <div className={styles.uploadSection}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className={styles.fileInput}
          />
          {uploadedImage && (
            <div className={styles.previewContainer}>
              <img
                src={uploadedImage}
                alt="上传图片"
                className={styles.previewImage}
              />
            </div>
          )}
        </div>

        <textarea
          className={styles.promptInput}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="请输入图片描述..."
          rows={4}
        />
        <button 
          className={styles.generateButton}
          onClick={handleGenerate}
          disabled={isLoading || !prompt.trim()}
        >
          {isLoading ? '生成中...' : '生成图片'}
        </button>
      </div>

      {isLoading && (
        <div className={styles.loadingSection}>
          <div className={styles.spinner}></div>
          <p>正在生成图片，请稍候...</p>
        </div>
      )}

      {generatedImage && !isLoading && (
        <div className={styles.resultSection}>
          <img 
            src={generatedImage} 
            alt="生成的图片"
            className={styles.generatedImage}
          />
        </div>
      )}
    </div>
  );
};

export default ImageGeneration;