import React from 'react';
import { FloatButton } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const AIChat = () => {
    return (
        <>
            <iframe 
                src="https://live2d.aiwa8.com/" 
                style={{ width: '100%', height: '100vh', border: 'none' }} 
                title="随心聊"
            />
            <FloatButton onClick={() => window.location.href = '/'} icon={<LogoutOutlined />} />
        </>
    );
};

export default AIChat;