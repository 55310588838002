// 去除 str 两端 char
export function trim(str, char) {
  if (char) {
    str = str.replace(new RegExp("^\\" + char + "+|\\" + char + "+$", "g"), "");
  }
  return str.replace(/^\s+|\s+$/g, "");
}

// 判断是否是移动端
export function isMobile() {
  const userAgentInfo = navigator.userAgent;
  const mobileAgents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  const mobileFlag = mobileAgents.some((mobileAgent) => {
    return userAgentInfo.indexOf(mobileAgent) > 0;
  });
  return mobileFlag;
}

// 判断是否是首页
export function isIndex() {
  // eslint-disable-next-line no-unused-expressions
  return window.location.pathname === "/";
}

// 缓存本地 token
export function setToken(id, username, token, expiresIn) {
  localStorage.setItem("id", id);
  localStorage.setItem("username", username);
  localStorage.setItem("token", token);
  localStorage.setItem("expiresIn", Math.floor(Date.now() / 1000) + expiresIn);
}

// 清除本地 token
export function removeToken() {
  localStorage.removeItem("id");
  localStorage.removeItem("username");
  localStorage.removeItem("token");
  localStorage.removeItem("expiresIn");
}
