import { request } from '../utils/axios/request';

// ai列表
export const $aiConfigList = async (params) => {
  return await request.post("/aiConfig/list", { ...params });
};


// ai详情 
export const $aiConfigDetail = async (params) => {
  return await request.post("/aiConfig/detail/" + params);
};

// ai生成
export const $aiGeneration = async (params) => {
  return await request.post("/aiConfig/generation/" + params.id, { ...params.qos });
};
