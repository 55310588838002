import React from 'react';

let notificationInstance = null;

const Notification = {
  show: (message, isError = false) => {
    if (notificationInstance) {
      document.body.removeChild(notificationInstance);
    }

    const notification = document.createElement('div');
    notification.style.cssText = `
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      padding: 12px 24px;
      border-radius: 4px;
      background-color: ${isError ? '#ff4d4f' : '#52c41a'};
      color: white;
      z-index: 9999;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
      font-size: 16px;
      min-width: 200px;
      text-align: center;
      font-family: 'CustomFont', sans-serif;
    `;

    notification.textContent = message;
    document.body.appendChild(notification);
    notificationInstance = notification;

    setTimeout(() => {
      if (notification.parentNode) {
        notification.style.opacity = '0';
        setTimeout(() => {
          if (notification.parentNode) {
            document.body.removeChild(notification);
          }
          if (notificationInstance === notification) {
            notificationInstance = null;
          }
        }, 300);
      }
    }, 3000);
  }
};

export default Notification; 