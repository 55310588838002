import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.css';

const Carousel = ({ children, autoPlay = true, interval = 5000, onPrev, onNext }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prev) => (prev + 1) % React.Children.count(children));
      if (onNext) onNext();
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prev) => (prev - 1 + React.Children.count(children)) % React.Children.count(children));
      if (onPrev) onPrev();
    }
  };

  useEffect(() => {
    const transitionTimer = setTimeout(() => {
      setIsTransitioning(false);
    }, 500);

    return () => clearTimeout(transitionTimer);
  }, [currentIndex]);

  // 添加自动播放功能
  useEffect(() => {
    if (autoPlay) {
      const autoPlayTimer = setInterval(nextSlide, interval);
      return () => clearInterval(autoPlayTimer);
    }
  }, [autoPlay, interval]);

  return (
    <div className={styles.carousel}>
      <button className={`${styles.carouselButton} ${styles.prevButton}`} onClick={prevSlide}>
        &lt;
      </button>
      <button className={`${styles.carouselButton} ${styles.nextButton}`} onClick={nextSlide}>
        &gt;
      </button>
      <div 
        className={styles.carouselInner} 
        style={{ 
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out'
        }}
      >
        {React.Children.map(children, (child) => (
          <div className={styles.carouselItem}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel; 