import { request } from '../utils/axios/request';

// 登录短信发送接口
export const $sms = async (params) => {
  return await request.post("/sms/login", { ...params });
};

// 登录接口
export const $login = async (params) => {
  return await request.post("/user/login", { ...params });
};
