import React, { useState } from 'react';
import styles from './CaiShenGeneration.module.css';
import { customCaiShenURL } from '../../config/config';

const CaiShenGeneration = () => {
  const [wish, setWish] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerate = async () => {
    if (!wish.trim()) return;
    
    setIsLoading(true);
    try {
      const promptText = wish;
      const response = await fetch(customCaiShenURL + '?prompt=' + promptText, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem("token")
        },
      });

    //   if (!response.ok) {
    //     throw new Error('生成图片失败');
    //   }

      const data = await response.json();
      console.log(data);
      setGeneratedImage(data.data); // 假设API返回的是 image_url 字段
      
    } catch (error) {
      console.error('生成财神图片时出错:', error);
      // 可以添加一个错误提示
      alert('生成图片失败，请稍后重试');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>AI 财神生成</h1>
      <p className={styles.description}>
        输入姓名和心愿，让AI为您生成专属的财神图像
      </p>
      
      <div className={styles.inputSection}>

        <div className={styles.inputGroup}>
          <label htmlFor="wish">心愿许愿</label>
          <textarea
            id="wish"
            className={styles.textarea}
            value={wish}
            onChange={(e) => setWish(e.target.value)}
            placeholder="请输入您的心愿..."
            rows={4}
          />
        </div>

        <button 
          className={styles.generateButton}
          onClick={handleGenerate}
          disabled={isLoading || !wish.trim()}
        >
          {isLoading ? '祈福生成中...' : '生成财神图'}
        </button>
      </div>

      {isLoading && (
        <div className={styles.loadingSection}>
          <div className={styles.spinner}></div>
          <p>正在生成您的专属财神，请稍候...</p>
        </div>
      )}

      {generatedImage && !isLoading && (
        <div className={styles.resultSection}>
          <video 
            src={generatedImage} 
            alt="财神视频"
            className={styles.generatedImage}
            controls
            autoPlay
            loop
            muted
          />
          <div className={styles.shareSection}>
            <p className={styles.shareText}>长按视频保存或分享给好友</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaiShenGeneration; 