import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import styles from './Home.module.css';
import Carousel from '../../components/Carousel';
import LoginModal from '../../components/LoginModal';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);
  
  const slides = [
    {
      desktop: '/hero-bg1.jpg',
      mobile: '/hero-bg1-mobile.jpg'
    },
    {
      desktop: '/hero-bg2.jpg',
      mobile: '/hero-bg2-mobile.jpg'
    },
    {
      desktop: '/hero-bg3.jpg',
      mobile: '/hero-bg3-mobile.jpg'
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleNavigate = (e, path) => {
    e.preventDefault();
    const isLoggedIn = localStorage.getItem('token');
    console.log('Login status:', isLoggedIn);
    
    if (!isLoggedIn) {
      console.log('Showing login modal');
      setShowLoginModal(true);
    } else {
      window.location.href = path;
    }
  };

  // Add a function to get the appropriate image based on screen width
  const getSlideImage = (slide) => {
    return window.innerWidth > 768 ? slide.desktop : slide.mobile;
  };

  // 自动轮播
  useEffect(() => {
    const timer = setInterval(nextSlide, 5000); // 每5秒切换一次
    return () => clearInterval(timer);
  }, []);

  return (
    <Layout>
      <div className={styles.carouselContainer}>
        {/* <div className={styles.socialIcons}>
          <a href="#" className={styles.socialIcon} title="微信">
            <img src="/icons/wechat.png" alt="微信" />
          </a>
          <a href="#" className={styles.socialIcon} title="微博">
            <img src="/icons/weibo.png" alt="微博" />
          </a>
          <a href="#" className={styles.socialIcon} title="哔哩哔哩">
            <img src="/icons/bilibili.png" alt="哔哩哔哩" />
          </a>
          <a href="#" className={styles.socialIcon} title="抖音">
            <img src="/icons/douyin.png" alt="抖音" />
          </a>
          <a href="#" className={styles.socialIcon} title="QQ">
            <img src="/icons/qq.png" alt="QQ" />
          </a>
        </div> */}
        <Carousel>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={styles.heroSlide}
              style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${getSlideImage(slide)})`,
                width: '100%',
                height: 'calc(100vh - 54.5px)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className={styles.heroContent}>
                <h1 style={{ 
                  textAlign: 'left', 
                  paddingLeft: window.innerWidth > 768 ? 100 : 20,
                  fontFamily: 'CustomFont',
                  fontSize: window.innerWidth > 768 ? '1.8rem' : '1.2rem'
                }}>随心定-你的专属公仔</h1>
                <p style={{ 
                  textAlign: 'left', 
                  paddingLeft: window.innerWidth > 768 ? 100 : 20,
                  fontFamily: 'CustomFont',
                  fontSize: window.innerWidth > 768 ? '1.2rem' : '1rem'
                }}>30秒完成定制</p>
                <p style={{ 
                  textAlign: 'left', 
                  paddingLeft: window.innerWidth > 768 ? 100 : 20,
                  fontFamily: 'CustomFont',
                  fontSize: window.innerWidth > 768 ? '1.2rem' : '1rem'
                }}>48小时敏捷交付</p>
              </div>
            </div>
          ))}
        </Carousel>
        
        <div className={styles.floatingBoxesContainer}>
          <a href="/quick-group" className={styles.floatingBox} 
            onClick={(e) => handleNavigate(e, '/quick-group')}
            style={{
              backgroundImage: `url(/image01.jpeg)`,
              border: '5px solid skyblue',
              textDecoration: 'none',
              position: 'relative',
              width: window.innerWidth > 768 ? '150px' : '100px',
              height: window.innerWidth > 768 ? '100px' : '50px'
            }}>
            <span className={styles.topText} style={{ 
              fontFamily: 'CustomFont',
              fontSize: window.innerWidth > 768 ? '16px' : '12px'
            }}>一键成团</span>
            <span className={styles.bottomText} style={{ 
              fontFamily: 'CustomFont',
              fontSize: window.innerWidth > 768 ? '14px' : '10px'
            }}>组队定制更优惠</span>
          </a>
          <a href="/custom-design" className={styles.floatingBox}
            onClick={(e) => handleNavigate(e, '/custom-design')}
            style={{
              backgroundImage: `url(/image02.jpeg)`,
              border: '5px solid skyblue',
              textDecoration: 'none',
              position: 'relative',
              width: window.innerWidth > 768 ? '150px' : '100px',
              height: window.innerWidth > 768 ? '100px' : '50px'
            }}>
            <span className={styles.topText} style={{ 
              fontFamily: 'CustomFont',
              fontSize: window.innerWidth > 768 ? '16px' : '12px'
            }}>专属定制</span>
            <span className={styles.bottomText} style={{ 
              fontFamily: 'CustomFont',
              fontSize: window.innerWidth > 768 ? '14px' : '10px'
            }}>独一无二专属爱</span>
          </a>
        </div>
        {showLoginModal && (
          <LoginModal 
            isOpen={showLoginModal}
            onClose={() => {
              console.log('Closing modal');
              setShowLoginModal(false);
            }}
            onLoginSuccess={(userData) => {
              // 登录成功后的处理
              console.log('Login success:', userData);
              setShowLoginModal(false);
              // 可以在这里添加跳转逻辑
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default Home; 