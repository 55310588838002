import React, { useState, useEffect } from 'react';
import styles from './AppSquare.module.css';
import { useNavigate } from 'react-router-dom';
import { $aiConfigList } from '../../api/aiConfigAPI';

const AppSquare = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [apps, setApps] = useState([]);
  const navigate = useNavigate();

  // const apps = [{
  //   id: 1,
  //   title: '图片生成',
  //   description: '通过文本描述生成图片',
  //   icon: '/image01.jpeg',
  //   link: '/image-generation'
  // },
  // {
  //   id: 2,
  //   title: 'AI财神',
  //   description: 'AI财神生成',
  //   icon: '/image02.jpeg',
  //   link: '/cai-shen-generation'
  // }];

  useEffect(() => {
    const fetchApps = async () => {
      try {
        const response = await $aiConfigList();
        setApps(Array.isArray(response.data.list) ? response.data.list : []);
      } catch (error) {
        console.error('Failed to fetch apps:', error);
        setApps([]);
      }
    };
    fetchApps();
  }, []);

  const filteredApps = Array.isArray(apps) ? apps.filter(app => 
    app.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    app.description.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 style={{ fontSize: '18px' }}>
          <span 
            onClick={() => navigate('/')} 
            style={{ cursor: 'pointer' }}
          >
            首页
          </span>
          <span className={styles.separator}>&gt;</span> 随心创
        </h1>
        <div className={styles.searchBar} style={{ textAlign: 'center' }}>
          <input
            type="text"
            placeholder="搜索应用..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
            style={{ margin: '0 auto' }}
          />
        </div>
      </div>
      
      <div className={styles.appGrid}>
        {filteredApps.map(app => (
          <div 
            key={app.id} 
            className={styles.appCard}
            onClick={() => navigate('/ai-generation?id=' + app.id)}
            style={{ cursor: 'pointer' }}
          >
            <div className={styles.appContent}>
              <div className={styles.appInfo}>
                <h3 className={styles.appTitle}>{app.title}</h3>
                <p className={styles.appDescription}>{app.description}</p>
              </div>
              <img src={app.icon ? app.icon : '/image01.jpeg'} alt={app.title} className={styles.appIcon} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppSquare; 