import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './AIGeneration.module.css';
import { $aiConfigDetail } from '../../api/aiConfigAPI';
import { customKnowledgeURL, ossBaseURL, aiGenerationURL } from '../../config/config';


const AIGeneration = () => {
    const [searchParams] = useSearchParams();
    const [config, setConfig] = useState(null);
    const [formData, setFormData] = useState({});  // 新增状态来存储所有表单数据
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
  
    const id = searchParams.get('id');
  
    useEffect(() => {
        const fetchConfig = async () => {
          try {
            const response = await $aiConfigDetail(id);
            // Parse JSON string to array if needed
            const params = typeof response.data.params === 'string'
              ? JSON.parse(response.data.params)
              : response.data.params || [];
            
            // Ensure params is an array
            const paramsArray = Array.isArray(params) ? params : [];
            
            setConfig({
              ...response.data,
              params: paramsArray
            });
            // Initialize form data
            const initialFormData = {};
            paramsArray.forEach(param => {
              initialFormData[param.name] = '';
            });
            setFormData(initialFormData);
          } catch (error) {
            console.error('Failed to fetch config:', error);
          }
        };
    
        if (id) {
          fetchConfig();
        }
      }, [id]);
    
      if (!config) {
        return <div className={styles.container}>Loading...</div>;
      }
  
    const handleInputChange = async (name, value) => {
      console.log('value', value);
      if (value instanceof File) {
        try {
          const formData = new FormData();
          formData.append('knowledge', value);
          const response = await fetch(customKnowledgeURL, {
            method: 'POST',
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            body: formData,
          });
          console.log('response', response);
          if (!response.ok) {
            throw new Error('生成失败');
          }
          const data = await response.json();
          console.log('Generated image result:', data.data);
          setFormData(prev => ({
            ...prev,
            [name]: ossBaseURL + data.data // 假设接口返回的是 {url: "图片链接"}
          }));
        } catch (error) {
          console.error('Failed to upload file:', error);
        }
      } else {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const submitData = {
          id: id,
          qos: Object.entries(formData).map(([name, value]) => ({
            name,
            type: config.params.find(param => param.name === name)?.type || 'text',
            value
          }))
        };
        
        console.log('Submitting data:', submitData);
        
        // 创建 AbortController 实例
        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), 600000); // 600秒超时

        const response = await fetch(aiGenerationURL + id, {
          method: 'POST',
          headers: {
            'Authorization': localStorage.getItem("token"),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submitData.qos),
          signal: controller.signal // 添加 signal
        });
        
        clearTimeout(timeout); // 清除超时计时器
        
        if (!response.ok) {
          throw new Error('Generation failed');
        }
        
        const result = await response.json();
        setResult(result.data);  // 设置返回结果
        console.log('response', result);
      } catch (error) {
        console.error('Generation failed:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const renderFormField = (param) => {
      switch (param.type) {
        case 'image':
          return (
            <div className={styles.imageField}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange(param.name, e.target.files[0])}
                required={param.required}
                className={styles.input}
              />
              {formData[param.name] && (
                <img 
                  src={formData[param.name]} 
                  alt="Uploaded preview" 
                  className={styles.imagePreview} 
                />
              )}
            </div>
          );
        case 'text':
        default:
          return (
            <textarea
              value={formData[param.name] || ''}
              onChange={(e) => handleInputChange(param.name, e.target.value)}
              placeholder={param.desc}
              className={styles.input}
              rows={4}
              required={param.required}
            />
          );
      }
    };
  
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>{config.title || 'AI Generation'}</h1>
          <p className={styles.description}>{config.description}</p>
        </div>

        <form onSubmit={handleSubmit} className={styles.form}>
          {(config.params || []).map((param, index) => (
            <div key={param.name} className={styles.formField}>
              <label className={styles.label}>
                {param.desc}
                {param.required && <span className={styles.required}>*</span>}
              </label>
              {renderFormField(param)}
              {param.type === 'text' && (
                <div className={styles.charCount}>
                  {formData[param.name]?.length || 0} 字符
                </div>
              )}
            </div>
          ))}
          <button 
            type="submit" 
            className={`${styles.submitButton} ${loading ? styles.loading : ''}`}
            disabled={loading || !Object.values(formData).some(value => value)}
          >
            {loading ? (
              <>
                <span className={styles.spinner}></span>
                生成中...
              </>
            ) : '生成'}
          </button>
        </form>

        {result && (
          <div className={styles.result}>
            <h2 className={styles.resultTitle}>生成结果</h2>
            <div className={styles.resultContent}>
              {result.map((item, index) => (
                <div key={index} className={styles.resultItem}>
                  <h3 className={styles.resultItemTitle}>{item.name}</h3>
                  {item.type === 'image' && (
                    <img 
                      src={item.value} 
                      alt={item.name} 
                      className={styles.resultImage} 
                    />
                  )}
                  {item.type === 'video' && (
                    <video 
                      controls 
                      className={styles.resultVideo}
                      src={item.value}
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
};

export default AIGeneration; 